body {
    background-color: #EAE7DC;
  }
.color-nav{
    background-color: #D8C3A5;
    border-bottom: 1px solid black;
    min-height: 60px;
}
.collapseText{
  font-size: 35px;
  text-align: center;
  font-family: 'Quicksand';
}
