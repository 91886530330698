:root{
    --legend-width: 175px;
}
body{
    font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
    font-weight: 200;
}
input {
      border-top-style: hidden;
      border-right-style: hidden;
      border-left-style: hidden;
      border-bottom-style: hidden;
      background-color: #eee;
      outline-width: 0px;
      font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
      font-weight: 100;
      padding: 0px;
      letter-spacing: 2px;
      font-size: 17.5px;
      color: #737373;
      min-width: 175px;
      max-width: 300px;
      height: 22px;
      text-align: center;
}
input::placeholder{
      font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
      font-weight: 100;
      letter-spacing: 1px;
      color: darkgrey;
      font-size: 17.5px;
      text-align: center;
}
label {
    background-color: #eee;
    padding: 10px;
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 5px;
    font-weight: 100;
    font-size: 17.5px;
    color: #737373;
    letter-spacing: 2px;
}
#url {
    cursor: pointer;
    color: black;
    padding: 15px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    line-height:22px;
    letter-spacing: 2.5px;
}
#url:hover{
    background-color:  #f2f2f2;
}
head2{
    font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
    font-size: 50px;
    padding: 10px;
    font-weight: 300;
}
head3{
    font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
    font-weight: 200;
    font-size: 30px;
}
i{
    font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
    font-style: italic;
    padding: 10px;
    font-weight: 300;
}
gt{
    color:#3eb63e;
    font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
    font-size: 20px;
    padding: 10px;
    font-weight: 300;
}
url-box {
    background-color:#eee;
    padding: 15px;
    border-radius: 5px;
    line-height:22px;
}
paddingbox{
    padding: 20px;
}

#style{
    cursor: pointer;
    padding: 8px;
    line-height: 14px;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    border-radius: 4px;

    font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;;
    font-weight: 300;
    font-size: 13px;
    color: #575757;
    background-color: #f5f5f5;
    letter-spacing: 1px;
    box-shadow: 0px 0px 4px #6b6b6b;
}
#style:hover{
    background-color:#e9e9e9;
}

#legendbox{
    background-color:#eee;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px #6b6b6b;
    margin: 5px;
    font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;;
    font-weight: 100;
    font-size: 20px;
    color: #575757;
    letter-spacing: 2px;
    text-align: center;
}
#legendbox:hover{
    background-color:#e9e9e9;
    box-shadow: 0px 0px 4px #535353;
}

#container{
    padding: 0px;
    margin: 0px;
    line-height: 13px;
    text-align: left;
    transition: all 0.2s ease-in;
}
#container:hover{
    padding-right: 3em;
    transform: scale(1.3);
    transform-origin: left;
    transition: 0.2s ease-in;
}

#aligner{
    text-align: left;
}

leg#meaning{
    font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;;
    font-weight: 400;
    font-size: 10px;
    color: #575757;
    letter-spacing: 1px;
    margin: 0px;
    margin-left: 3px;
    padding: 0px;
}

.colors{
    background-color: coral;
    border-radius: 3px;
    font-size: 10px;
    margin: 0px;
}
.grey{
    background-color: grey;
    color: grey;
}
.red-orange{
    background-color: rgb(255,85,0);
    color: rgb(255,85,0);
}
.red{
    background-color: rgb(255,0,0);
    color: rgb(255,0,0);
}
.green{
    background-color: rgb(84,255,0);
    color: rgb(84,255,0);
}
.forestgreen{
    background-color: rgb(34, 139, 34);
    color: rgb(34, 139, 34);
}
.orange{
    background-color: rgb(255,170,0);
    color: rgb(255,170,0);
}
.green-yellow{
    background-color: rgb(170,255,0);
    color: rgb(170,255,0);
}
.yellow{
    background-color: rgb(233, 237, 0);
    color: rgb(233, 237, 0);
}
.greener{
    background-color: rgb(0,255,0);
    color: rgb(0,255,0);
}
h3{
    padding: 20px;
    font-size: large;
}
p{
    padding-left: 20px;
}
.button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 6px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin: 3px 2px;
    margin-left: 16px;
    border-radius: 12px;
  }