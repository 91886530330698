.chart {
    min-height: 250px;
    min-width: 500px;
}
h4 {
    font-size: 22px;
    font-family: Arial;
}
.infoDiv {
    display: grid;
}